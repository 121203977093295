import React, { useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import "aos/dist/aos.css";


export default function Shop() {
  const [t] = useTranslation("global");
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  return (
    <Fragment>

      {/*Coffee Section Shop*/}
      <section className="Container--shop--articles">
      <h1 className="Toasted-coffee-title"> {t("Shop.Title")}</h1>
      {/*Coffee Article TYPICA AND GEISHA*/}
        <article className="Coffe-shop-section  coffee-shop--article">
        <div className="Container-shop-info Container-shop-info-Geisha ">
              <ul>
              <h2 className="Title--características">{t("Shop.Characteristics")}</h2>

              <li className="Container--info--text li-coffee">{t("Shop.Featuregeisha1")}</li>
              <li className="Container--info--text li-coffee">{t("Shop.Featuregeisha2")}</li>
              <li className="Container--info--text li-coffee">{t("Shop.Featuregeisha3")}</li>
              <li className="Container--info--text li-coffee">{t("Shop.Featuregeisha4")}</li>
            </ul>
        </div>
        <div data-aos="fade-up" className="Container-shop-image Container-shop-image-Geisha">
        <h1 className="Container--info--title">GEISHA</h1>
          <div  className="Coffee-image image-geisha"></div>
          <div className="Container-addtocart">
            <p className="Addtocart--price">$23.00 250Gr</p>
            <p className="Addtocart--text">{t("Shop.cart")}</p>
            <a href="https://www.cuanto.app/grandelval/p/397fd3" className="cart--button">{t("Shop.Grainbtn")}</a> 
          <a href="https://www.cuanto.app/grandelval/p/61119b" className="cart--button" >{t("Shop.Groundbtn")}</a></div>
        </div>
        <article className="Typica-articule">
        <div data-aos="fade-up" className="Container-shop-image">
        <h1 className="Container--info--title">TYPICA</h1>
          <div className="Coffee-image image-typica"></div>
          <div className="Container-addtocart">
          <p className="Addtocart--price">$15.00 250Gr</p>
            <p className="Addtocart--text">{t("Shop.cart")}</p>

            <a href="https://www.cuanto.app/grandelval/p/f21072" className="cart--button">{t("Shop.Grainbtn")}</a> 
          <a href="https://www.cuanto.app/grandelval/p/809d3c" className="cart--button" >{t("Shop.Groundbtn")}</a></div>
          
        </div>
        <div className="Container-shop-info">
            <ul>
            <h2 className="Title--características">{t("Shop.Characteristics")}</h2>
              <li className="Container--info--text li-coffee">{t("Shop.Featuretypica1")}</li>
              <li className="Container--info--text li-coffee">{t("Shop.Featuretypica2")}</li>
              <li className="Container--info--text li-coffee">{t("Shop.Featuretypica3")}</li>
              <li className="Container--info--text li-coffee">{t("Shop.Featuretypica4")}</li>
            </ul>
        </div>
        </article>
        
      </article> 
  
          {/*Coffee Article  BLEND AND JAVA*/}
          <article className="Coffe-shop-section  coffee-shop--article">
       
        <div className="Container-shop-info Container-shop-info-Blend">
            <ul>
            <h2 className="Title--características">{t("Shop.Characteristics")}</h2>

              <li className="Container--info--text li-coffee ">{t("Shop.Featureblend1")}</li>
              <li className="Container--info--text li-coffee">{t("Shop.Featureblend2")}</li>
              <li className="Container--info--text li-coffee">{t("Shop.Featureblend3")}</li>
              <li className="Container--info--text li-coffee">{t("Shop.Featureblend4")}</li>
              <li className="Container--info--text li-coffee">{t("Shop.Featureblend5")}</li>
            </ul>
        </div>
        <div data-aos="fade-up" className="Container-shop-image Container-shop-image-blend">
        <h1 className="Container--info--title">BLEND</h1>
          <div data-aos="fade-up" className="Coffee-image image-blend"></div>
          <div className="Container-addtocart">
          <p className="Addtocart--price">$13.00 250Gr</p>

            <p className="Addtocart--text">{t("Shop.cart")}</p>
            <a href="https://www.cuanto.app/grandelval/p/141f7a" className="cart--button">{t("Shop.Grainbtn")}</a> 
          <a href="https://www.cuanto.app/grandelval/p/403f6a"className="cart--button" >{t("Shop.Groundbtn")}</a></div>
          
        </div>
        <article className="Typica-articule">
        
        <div data-aos="fade-up" className="Container-shop-image">
        <h1 className="Container--info--title">JAVA</h1>
          <div data-aos="fade-up" className="Coffee-image"></div>
          <div className="Container-addtocart">
          <p className="Addtocart--price">$20.00 250Gr</p>
            <p className="Addtocart--text">{t("Shop.cart")}</p>
            <a href="https://www.cuanto.app/grandelval/p/141f7a" className="cart--button">{t("Shop.Grainbtn")}</a> 
          <a href="https://www.cuanto.app/grandelval/p/403f6a"className="cart--button" >{t("Shop.Groundbtn")}</a></div>
        </div>
        <div className="Container-shop-info">
            <ul>
            <h2 className="Title--características">{t("Shop.Characteristics")}</h2>

              <li className="Container--info--text li-coffee ">{t("Shop.Featurejava1")}</li>
              <li className="Container--info--text li-coffee">{t("Shop.Featurejava2")}</li>
              <li className="Container--info--text li-coffee">{t("Shop.Featurejava3")}</li>
              <li className="Container--info--text li-coffee">{t("Shop.Featurejava4")}</li>
            </ul>
        </div>
        </article>
    
        

      </article>   
  

            {/*Coffee Article  Boxes*/}
       
      </section>
      
        </Fragment>
  );
}
