import React, {useState}from "react"
import { useTranslation } from "react-i18next";
import togglemenu from "../assets/icons/Toggle.png"
import {NavLink} from "react-router-dom";
import goldenlogo from "../assets/images/goldenlogo.png";



export default function LandingPage(){
const [t,i18n] = useTranslation("global");
const [showLinks, setShowLinks] = useState(false);


    return (
        <div>
            <section className="home--page--history">
        <nav className="nav">
        <div className="nav__logo">
            <img src={goldenlogo} alt="titlelogo"></img>
        </div>
            <div className="nav__menu nav__landing"  id={showLinks ? "hidden" : ""}>
                <ul className="nav__list">
                    <li className="nav__item"><NavLink to="/" className="nav__link activate" >{t("Header.Home")}</NavLink></li>
                    <li className="nav__item"><NavLink to="" className="nav__link">{t("Header.History")}</NavLink></li>

            <li className="nav__item">
              <NavLink to="/Coffeeshop" className="nav__link">
              {t("Header.Store")}
              </NavLink>
            </li>
                    <li className="nav__item"><NavLink to="/Farms" className="nav__link">{t("Header.Farms")}</NavLink></li>
                    <li className="nav__item"><NavLink to="/OurTeam" className="nav__link">{t("Header.Team")}</NavLink></li>
                    <li className="nav__item">
                    <button  className="LenguageButton" onClick={()=> i18n.changeLanguage("en")}>EN</button>
                    <button  className="LenguageButton" onClick={()=> i18n.changeLanguage("es")}>ES</button></li>
                </ul>
            </div>
            <div>
            <img className="nav__toggle" id="nav-toggle" src={togglemenu} alt="toggle" onClick={() => setShowLinks(!showLinks)}></img>
            </div>
        </nav>
        <div className="Landing--Home--history">
        
        <div className="Title--History--Container">{t("History.Title")}</div>
        </div>
        </section>
   
        </div>
    );
}
