import React,{useState, Fragment} from 'react'
import {NavLink} from "react-router-dom"
import { useTranslation } from "react-i18next";
import togglemenu from "../assets/icons/Toggle.png";
import {  withRouter } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"
import goldenlogo from "../assets/images/goldenlogo.png";

import  {closeSessionAction} from "../Redux/UserDucks"


const NavbarShop = (props) => {
    
    //Translations
    const [t, i18n] = useTranslation("global");

     //NavLinks
    const [showLinks, setShowLinks] = useState(false);
    
     //Sign Out
    const dispatch = useDispatch()

    const closession = () => {
        dispatch(closeSessionAction())
        props.history.push("/")
    }

    const active = useSelector(store => store.user.active)


    return(
      <Fragment> 
        <nav className="nav Nav--shop">
        <div className="nav__logo">
        <img src={goldenlogo} alt="titlelogo"></img>
    </div>
        
        
        <div className="nav__menu__shop" id={showLinks ? "hidden-shop" : ""}>
          <ul className="nav__list nav__list__shop">

          
            <li className="nav__item">
              <NavLink to="/" className="nav__link nav__linkshop activate">
                {t("Header.Home")}
              </NavLink>
            </li>

            <li className="nav__item">
              <NavLink to="/History" className="nav__link nav__linkshop">
                {t("Header.History")}
              </NavLink>
            </li>
            
            <li className="nav__item">
              <NavLink to="/Farms" className="nav__link  nav__linkshop">{t("Header.Farms")}
              </NavLink>
              </li>
              <li className="nav__item">
              <NavLink to="/Contact" className="nav__link">
                {t("Header.Contact")}
              </NavLink>
            </li>

            <li className="nav__item">
              <a className="cart-item nav__link" href="https://www.cuanto.app/grandelval" > {t("Header.Cart")}</a>
            </li>
            <li className="nav__item"><NavLink to="/OurTeam" className="nav__link">{t("Header.Team")}</NavLink></li>

            <li className="nav__item">

              <button
                className="LenguageButton"
                onClick={() => i18n.changeLanguage("en")}
              >
                EN
              </button>
              <button
                className="LenguageButton"
                onClick={() => i18n.changeLanguage("es")}
              >
                ES
              </button>
              </li>

            <li className="nav__item">
              {
                active ? (
                <>
                <button className="LenguageButton" onClick = {() => closession()}>
                {t("Header.SignOut")}
                </button>
                </>
            ):( <NavLink to="/LogIn" className="nav__link__login"> <button className="Login--button" >{t("Header.Log-In")}
            </button></NavLink>)
            }
            </li>
        </ul>
        </div>
        <div>
        <img
            className="nav__toggle"
            id="nav-toggle"
            src={togglemenu}
            alt="toggle"
            onClick={() => setShowLinks(!showLinks)}
        ></img>
        </div>
    </nav>
    </Fragment>

    );


}

export default withRouter (NavbarShop)