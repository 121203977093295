import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import togglemenu from "../assets/icons/Toggle.png";
import goldenlogo from "../assets/images/goldenlogo.png";
import Pin from "../assets/icons/pin.svg";
import { NavLink } from "react-router-dom";
import GalleryGladyys from "./FarmGallery/SliderGladys";
import GalleryParadise from "./FarmGallery/SliderParadise";
import GalleryFortune from "./FarmGallery/SliderFortune";



export default function Farms() {
  const [t, i18n] = useTranslation("global");
  const [showLinks, setShowLinks] = useState(false);

  return (
    <div>
      <section className="home--page--farms">
        <nav className="nav Nav--farms">
          <div className="nav__logo">
              <img src={goldenlogo} alt="titlelogo"></img>
          </div>
          <div className="nav__menu nav__farm" id={showLinks ? "hidden" : ""}>
            <ul className="nav__list">
              <li className="nav__item">
                <NavLink to="/" className="nav__link activate">
                  {t("Header.Home")}
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink to="/History" className="nav__link">
                  {t("Header.History")}
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink to="/Farms" className="nav__link">
                  {t("Header.Farms")}
                </NavLink>
              </li>

            <li className="nav__item">
              <NavLink to="/Coffeeshop" className="nav__link">
              {t("Header.Store")}
              </NavLink>
            </li>
              <li className="nav__item">
                <NavLink to="/OurTeam" className="nav__link">
                  {t("Header.Team")}
                </NavLink>
              </li>
              <li className="nav__item">
                <button
                  className="LenguageButton"
                  onClick={() => i18n.changeLanguage("en")}
                >
                  EN
                </button>
                <button
                  className="LenguageButton"
                  onClick={() => i18n.changeLanguage("es")}
                >
                  ES
                </button>
              </li>
            </ul>
          </div>
          <div>
            <img
              className="nav__toggle"
              id="nav-toggle"
              src={togglemenu}
              alt="toggle"
              onClick={() => setShowLinks(!showLinks)}
            ></img>
          </div>
        </nav>
        <div className="row">
          <div className="col">
            <h1>{t("Farms.Farms-title")}</h1>
            <p className="p--tag--farm">{t("Farms.Farms-text")}</p>
          </div>
          <div className="col--farm">
            <a href="#Gladys">
              <div className="card--farm card1">
              </div>
            </a>
            <a href="#Fortune">
              <div className="card--farm card2 ">
              </div>
            </a>
            <a href="#Paradise">
              <div className="card--farm card3">
              </div>
            </a>
          </div>
        </div>
      </section>
      <section className="Section--farms--container">
        <article className="Container--Gladys" id="Gladys">
                    <GalleryGladyys></GalleryGladyys>

          <div className="Gladys--info--container">

            <ul>
              <h2 className="Farms--title"> {t("Farms.Gladys-title")}</h2>
              <li>{t("Farms.Gladys-text")}</li>

              <li className="pin">
              <a href="https://www.google.com/maps/place/Bajo+Mono,+Los+Naranjos/data=!4m2!3m1!1s0x8fa5ec6509d483db:0x3d1efd79df9f5424?sa=X&ved=2ahUKEwjmvdLzitbwAhWjneAKHf91DfEQ8gEwAHoECAQQAQ"><img src={Pin} alt="google maps click" ></img></a>
              </li>
            </ul>
          </div>
        </article>
      </section>

      
       {/* Paradise Section*/}
       <section className="Section--farms--container">
        <article className="Container--Gladys" id="Fortune">
        <GalleryParadise></GalleryParadise>


          <div className="Gladys--info--container">
            <ul>
              <h2 className="Farms--title">{t("Farms.Paradise-title")}</h2>
              <li> {t("Farms.Paradise-text")}</li>
              <li className="pin">
                <a href="https://www.google.com/maps/place/Bajo+Mono,+Los+Naranjos/data=!4m2!3m1!1s0x8fa5ec6509d483db:0x3d1efd79df9f5424?sa=X&ved=2ahUKEwjmvdLzitbwAhWjneAKHf91DfEQ8gEwAHoECAQQAQ"><img src={Pin} alt="google maps click" ></img></a>
              </li>
            </ul>
          </div>
        </article>
      </section>

       {/* Fortune Section*/}
       <section className="Section--farms--container">
        <article className="Container--Gladys" id="Paradise">
        <GalleryFortune></GalleryFortune>


          <div className="Gladys--info--container ">


            <ul>
              <h2 className="Farms--title">{t("Farms.Fortune-title")}</h2>
              <li> {t("Farms.Fortune-text")}</li>
              <li className="pin">
                <a href="https://www.google.com/maps/place/Bajo+Mono,+Los+Naranjos/data=!4m2!3m1!1s0x8fa5ec6509d483db:0x3d1efd79df9f5424?sa=X&ved=2ahUKEwjmvdLzitbwAhWjneAKHf91DfEQ8gEwAHoECAQQAQ"><img src={Pin} alt="google maps click" ></img></a>
              </li>
            </ul>
          </div>
        </article>
      </section>

    </div>
  );
}
