import React, { useState,useEffect, Fragment} from 'react';
import { SliderData } from './SliderData';
import { SliderDataTwo } from './SliderDataTwo';
import { SliderDataThree } from './SliderDataThree';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';
import Aos  from "aos"
import "aos/dist/aos.css"

const ImageSlider = ({ slides }) => {

    useEffect(() => {
        Aos.init({ duration:3000 });
        },[]);

    const [current, setCurrent] = useState(0);
    const length = slides.length;

    const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
    };

    if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
    }
    

  return (
    <Fragment>
    <section className="Slider--Background--Container">
      {/* First Slider */}
    <section data-aos="zoom-in"  className='slider'>
      <AiOutlineArrowLeft className='left-arrow' onClick={prevSlide} />
      <AiOutlineArrowRight className='right-arrow' onClick={nextSlide} />
      {SliderData.map((slide, index) => {
        return (
          
          <div 
            className={index === current ? 'slide active' : 'slide'}
            key={index}
          >
            {index === current && (
              <img src={slide.image} alt='Gran del val images' className='image' />
            )}
            
          </div>
          
        );
      })}
    </section>

    {/* Second Slider */}
    <section data-aos="zoom-in"  className='slider'>
      <AiOutlineArrowLeft className='left-arrow' onClick={prevSlide} />
      <AiOutlineArrowRight className='right-arrow' onClick={nextSlide} />
      {SliderDataTwo.map((slide, index) => {
        return (
          
          <div
            className={index === current ? 'slide active' : 'slide'}
            key={index}
          >
            {index === current && (
              <img src={slide.image} alt='Gran del val images' className='image' />
            )}
            
            
            
          </div>
          
        );
      })}
    </section>

        {/* Third Slider */}
    <section data-aos="zoom-in"  className='slider'>
      <AiOutlineArrowLeft className='left-arrow' onClick={prevSlide} />
      <AiOutlineArrowRight className='right-arrow' onClick={nextSlide} />
      {SliderDataThree.map((slide, index) => {
        return (
          
          <div
            className={index === current ? 'slide active' : 'slide'}
            key={index}
          >
            {index === current && (
              <img src={slide.image} alt='Gran del val images' className='image' />
            )}
            
          </div>
          
        );
      })}
    </section>
    </section>
    </Fragment>
  );
};

export default ImageSlider;