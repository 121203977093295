export const DataParadise = [
    {
        image:
          'https://objective-kare-2d6adc.netlify.app/assets/paradisefour.jpg'
      },
    {
      image:
      'https://objective-kare-2d6adc.netlify.app/assets/Eco.JPG'
    },
    {
        image:
        'https://objective-kare-2d6adc.netlify.app/assets/paradise2.jpg'
      }

  ];

