import {Route} from "react-router-dom";
import "./App.css";
//Import Components
import Header from "./components/Header";
import ContactForm from "./components/ContactForm";
import Footer from "./components/Footer";
import Team from "./components/Team";
import Farms from "./components/Farms";
import History from "./components/History"
import LandingPage from "./components/LandingPage"
import Login from "./components/Login"
import Shop from "./components/Shop"
import NavbarShop from "./components/NavShop"
import SliderF from "./components/SliderF"
import GreenBanner from "./components/CoffeBanner"
import NewProduct from "./components/NewProduct"
import SliderShop from "./components/SliderShop"
import BannerGoShop from "./components/BannerGoShop"


function App() {
  return (
    <div className="App">
    
      <Route exact path="/" component={Header}/>
      <Route exact path="/" component={NewProduct}/>
      <Route exact path="/" component={ContactForm}/>
      <Route exact path="/" component={Footer}/>
      <Route exact path="/OurTeam" component={Team}/>
      <Route exact path="/OurTeam" component={Footer}/>
      <Route exact path="/Farms" component={Farms}/>
      <Route exact path="/Farms" component={SliderF}/>
      <Route exact path="/Farms" component={BannerGoShop}/>
      <Route exact path="/Farms" component={Footer}/>
      <Route exact path="/History" component={LandingPage}/>
      <Route exact path="/History" component={History}/>
      <Route exact path="/History" component={Footer}/>
      <Route exact path="/Register" component={Footer}/>
      <Route exact path="/LogIn" component={Login}/>
      <Route exact path="/LogIn" component={Footer}/>
      <Route exact path="/Coffeeshop" component={NavbarShop}/>
      <Route exact path="/Coffeeshop" component={SliderShop}/>
      <Route exact path="/Coffeeshop" component={Shop}/>
      <Route exact path="/Coffeeshop" component={GreenBanner}/>
      <Route exact path="/Coffeeshop" component={Footer}/>
      <Route exact path="/Contact" component={ContactForm}/>

      
    </div>
  );
}

export default App;
