export const SliderDataTwo = [
    {
        image:
          'https://objective-kare-2d6adc.netlify.app/assets/18.jpeg'
      },
    {
      image:
      'https://objective-kare-2d6adc.netlify.app/assets/7.jpeg'
    },
    {
        image:
        'https://objective-kare-2d6adc.netlify.app/assets/JapanPhotos/japan1.jpeg'
      },
      {
          image:
          'https://objective-kare-2d6adc.netlify.app/assets/17.jpeg'
      },
      {
          image:
          'https://objective-kare-2d6adc.netlify.app/assets/JapanPhotos/ecosecond.jpeg'
      }
      
    

  ];

