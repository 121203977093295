import React from "react";
import { useTranslation } from "react-i18next";
import goldenlogo from "../assets/images/goldenlogo.png";
import panama from "../assets/images/panama.png"



export default function Footer(){
    const [t] = useTranslation("global");
        return(
            <footer className="footer--section">
            <div className="footer__container ">
                <div className="footer__content">
                    <div  className="footer__logo"><img src={panama} alt="GoldenLogo" /></div>
                </div>
                <div className="footer__content">
                    <a href="/" className="footer__logo"><img src={goldenlogo} alt="GoldenLogo" /></a>
                </div>
                <div className="footer__content">
                    <div  className="footer__logo"><img src={panama} alt="GoldenLogo" /></div>
                </div>

        
            </div>
            <p className="footer__copy">&#169; {t("Footer.Right")}</p>
        </footer>
        
        );
    }

