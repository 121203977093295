export const SliderData = [
    {
        image:
          'https://objective-kare-2d6adc.netlify.app/assets/1.jpeg'
      },
    {
      image:
      'https://objective-kare-2d6adc.netlify.app/assets/8.jpeg'
    },
    {
      image:
      'https://objective-kare-2d6adc.netlify.app/assets/JapanPhotos/japan3.jpeg'
    },
    {
        image:
        'https://objective-kare-2d6adc.netlify.app/assets/13.jpeg'
    },
    {
        image:
        'https://objective-kare-2d6adc.netlify.app/assets/JapanPhotos/japanhiro.jpeg'
    }
    

  ];

