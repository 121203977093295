import React,{Fragment} from 'react'
import {NavLink} from "react-router-dom"
import { useTranslation } from "react-i18next";



export default function BannerGoShop () {
    const [t] = useTranslation("global");


    return(
    <Fragment>
        <section className="Banner-coffeegreen-container BannerGoShop">
            <section className="Articule-coffegreen-info">
                        <h2 className="Coffeegreen-title">{t("CoffeeBanner.GoStoreTitle")}</h2>
                        <p className="Coffeegreen-text">{t("CoffeeBanner.GoStoreText")}</p>
                        <NavLink to="/Coffeeshop" className="Greencoffee-btn">{t("Header.Shop-Now")}</NavLink>
            </section>
        </section>
    </Fragment>
    );
}