import {createStore, combineReducers, compose, applyMiddleware} from "redux"
import thunk from "redux-thunk"

import userReducer, {ReadUserActiveAction} from "./UserDucks"

//DevTools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    user: userReducer
})

export default function generateStore(){
    const store = createStore(rootReducer, composeEnhancers( applyMiddleware(thunk) ))
    ReadUserActiveAction()(store.dispatch)
    return store;
}