import {auth,firebase} from "../Firebase"


//Initial Data
const dataInitial = {
    loading: false,
    active: false
}

//type

const LOADING = "LOADING"
const USER_ERROR = "USER_ERROR"
const USER_SUCESS = "USER_SUCESS"
const SIGN_OUT = "SIGN_OUT"

//ReduceR
export default function userReducer (state = dataInitial,action){
    switch(action.type){
            case LOADING:
                return {...state,loading: true}
            case USER_ERROR:
                return {...dataInitial}
            case USER_SUCESS:
                return{...state,loading:false, user: action.payload, active:true}
            case SIGN_OUT:
                return {...dataInitial}
            default:
                return {...state}
    }

}

//Actions

export const loginuseraction = () => async (dispatch) =>{
    dispatch({
        type: LOADING
    })
    try{

        const provider = new firebase.auth.GoogleAuthProvider();
        const res = await auth.signInWithPopup(provider)
        console.log(res)
        dispatch({
            type: USER_SUCESS,
            payload:{
                uid:res.user.uid,
                email: res.user.email
            }

        })
        localStorage.setItem('user',JSON.stringify({
            uid:res.user.uid,
            email: res.user.email
        }))

    } catch (error){
        console.log(error)
        dispatch({
            type: USER_ERROR

        })
    }

}

export const ReadUserActiveAction = () => (dispatch) => {
    if(localStorage.getItem('user')){
        dispatch({
            type: USER_SUCESS,
            payload: JSON.parse(localStorage.getItem('user'))
        })
    }
}


export const closeSessionAction = () => (dispatch) =>{
     auth.signOut()
     localStorage.removeItem("user")
     dispatch({
         type:SIGN_OUT,
     })

}