import React, { useState,useEffect, Fragment} from 'react';
import { DataGladys } from './DataGladys';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';
import Aos  from "aos"
import "aos/dist/aos.css"

const GalleryGladys = ({ slides }) => {

    useEffect(() => {
        Aos.init({ duration:3000 });
        },[]);

    const [current, setCurrent] = useState(0);
    const length = slides.length;

    const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
    };

    if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
    }

  return (
    <Fragment>
      {/* First Slider */}
     
      {DataGladys.map((slide, index) => {
        
        
        return (
          
          
          <div 
      
            className={index === current ? 'slide active' : 'slide'}
            key={index}
          >  <AiOutlineArrowLeft className='left-arrow-farm ' onClick={prevSlide} />
          <AiOutlineArrowRight className='right-arrow-farm ' onClick={nextSlide} />
            {index === current && (
              <img src={slide.image} alt='Gran del val images' className="image-gladys" />
            )} 
          </div> 
          
        );
        
      
      })}
     
    </Fragment>
    
  );
};

export default GalleryGladys;