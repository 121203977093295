import React, {Fragment, useEffect}from "react";
import { useTranslation } from "react-i18next";
import Quality from "../assets/images/Quality.jpg"
import Aos  from "aos"
import "aos/dist/aos.css"




export default function NewProduct(){
    const [t] = useTranslation("global");

    useEffect(() => {
        Aos.init({ duration:3000 });
    },[]);


        return(
        <Fragment>
            <div className="Quality--container">
            <div className="Quality-info">
            <h1  data-aos="zoom-in" className="Quality-title">{t("Collection.Title")}</h1>
            <p data-aos="zoom-in"  className="Quality-text">{t("Collection.Text")}</p>
            </div>
            <div data-aos="zoom-in" className="Quality--image"><img src={Quality} alt="asdasd"></img></div>
            
            </div>
            
        </Fragment>
        
        );
    }



