import React from "react";
import { useTranslation } from "react-i18next";
import goldenlogo from "../assets/images/goldenlogo.png";
import { NavLink } from "react-router-dom";
import NavBar from "./NavBar"


export default function Header() {
  const [t] = useTranslation("global");
  
  return (
    <section id="header" className="home--page">
      <NavBar></NavBar>
      <div className="Home--container">
        <div className="Home--PageLogo">
          <img src={goldenlogo}  alt="titlelogo"></img>
          <NavLink to="/Coffeeshop">
            <button className="shopbutton--home">{t("Header.Shop-Now")}</button>
          </NavLink>
        </div>
        <div className="Home--page--title">
          <h1 className="Home--title--landing">{t("Header.Title")}</h1>
        </div>
      </div>
    </section>
  );
}
