import React from 'react';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import {BrowserRouter} from 'react-router-dom';

import ReactDOM from 'react-dom';

import {Provider}  from 'react-redux'
import generateStore  from './Redux/store'

import App from './App';
import reportWebVitals from './reportWebVitals';

//Translations

import global_es from "./translations/es/global.json"
import global_en from "./translations/en/global.json"

i18next.init({
  interpolation: { escapeValue: false},
  lng:"es",
  resources:{
    es:{
      global: global_es
    },
    en:{
      global: global_en
    }
  }
});


//Redux 

const store = generateStore()
ReactDOM.render(

  <React.StrictMode>
    <Provider store={store}>
    <BrowserRouter>
    <I18nextProvider i18n={i18next}>
    <App />
    </I18nextProvider>
    </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
