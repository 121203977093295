import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import togglemenu from "../assets/icons/Toggle.png"
import goldenlogo from "../assets/images/goldenlogo.png";
import {NavLink} from "react-router-dom";



export default function Team(){
    const [t,i18n] = useTranslation("global");
    const [showLinks, setShowLinks] = useState(false);


    return (
        <div><section className="home--page--team">
        <nav className="nav Nav--farms">
        <div className="nav__logo">
            <NavLink to="/">
            <img src={goldenlogo} alt="titlelogo"></img>
            </NavLink>
        </div>
            <div className="nav__menu nav__team"  id={showLinks ? "hidden" : ""}>
                <ul className="nav__list">
                    <li className="nav__item"><NavLink to="/" className="nav__link activate" >{t("Header.Home")}</NavLink></li>
                    <li className="nav__item"><NavLink to="/History" className="nav__link">{t("Header.History")}</NavLink></li>

            <li className="nav__item">
              <NavLink to="/Coffeeshop" className="nav__link">
              {t("Header.Store")}
              </NavLink>
            </li>
                    <li className="nav__item"><NavLink to="/Farms" className="nav__link">{t("Header.Farms")}</NavLink></li>
                    <li className="nav__item"><NavLink to="/OurTeam" className="nav__link">{t("Header.Team")}</NavLink></li>
                    <li className="nav__item">
                    <button  className="LenguageButton" onClick={()=> i18n.changeLanguage("en")}>EN</button>
                    <button  className="LenguageButton" onClick={()=> i18n.changeLanguage("es")}>ES</button></li>
                </ul>
            </div>
            <div>
            <img className="nav__toggle" id="nav-toggle" src={togglemenu} alt="toggle" onClick={() => setShowLinks(!showLinks)}></img>
            </div>
        </nav>
        <div className="Landing--Home--team">
        
        <div className="Title--Team--Container">{t("Team.Team-title")}</div>
        </div>
        </section>
    
    <section className="Section--Team--Container">
        <div className="Team--container">
        <div className="team"><div className="info team3"></div><div className="info infocard3"><h2>Ricardo, Edgar, Rodolfo Fernández De Dianous</h2><h4>{t("Team.Charge-three")}</h4></div></div>
        <div className="team"><div className="info team4"></div><div className="info infocard4"><h2>Ricardo Fernández De Obarrio </h2><h4>{t("Team.Charge-one")}</h4></div></div>
        <div className="team"><div className="info team5"></div><div className="info infocard5"><h2>Cristina Fernández Ford</h2><h4>{t("Team.Charge-two")}</h4></div></div>
        <div className="team"><div className="info team6"></div><div className="info infocard6"><h2>Denia Morales</h2><h4>{t("Team.Charge-five")}</h4><p>{t("Team.Years-services")}  {t("Team.Years21")}</p></div></div>
        <div className="team"><div className="info team7"></div><div className="info infocard6"><h2>Tity Alvarez</h2><h4>{t("Team.Charge-three")}</h4><p>{t("Team.Years-services")} {t("Team.Years11")} </p></div></div>
        <div className="team"><div className="info team8"></div><div className="info infocard6"><h2>Martín Montezuma</h2><h4>{t("Team.Charge-four")}</h4><p>{t("Team.Years-services")}  {t("Team.Years17")}</p></div></div>


        </div>
    
    </section>
    </div>
    );

}


