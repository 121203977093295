import React, {useEffect}from "react";
import { useTranslation } from "react-i18next";
import engineer from "../assets/images/ingenierodianous.jpg"
import teresita from "../assets/images/teresita.jpg"
import gladys from "../assets/images/gabrielygladys.gif"
import threegeneration from "../assets/images/3generation.jpeg"
import Eco from "../assets/images/Eco.JPG"
import exports from "../assets/images/bags.jpg"

import roasted from "../assets/images/roasted.jpg"

import Aos  from "aos"
import "aos/dist/aos.css"


export default function History(){
    useEffect(() => {
        Aos.init({ duration:3000 });
    },[]);

    const [t] = useTranslation("global");

    return(
        <div>
    <div className="container">
        <div className="timeline">
        <ul>   
            <li className="secondcard">
                <div className="timeline-content ">
                <h3 className="date date-year">1880</h3>
                <h1>1880</h1>
                <p data-aos="zoom-in" >{t("History.Lineone")}</p>
                </div>
            </li>

            <li className="secondcard">
            <div  className="timeline-content">
                <h3 data-aos="zoom-in" className="date">{t("History.Titleone")}</h3>
                <img data-aos="zoom-in" src={engineer} alt="Ingeniero Dianous y Señora"/>
            </div>
            </li>

            
            <li className="threecard">
                <div className="timeline-content">
                <h3 className="date date-year">1914 </h3>
                <h1>1914</h1>
                <p data-aos="zoom-in">{t("History.Linetwo")}</p>
                </div>
            </li>
            <li  className="threecard">
            <div className="timeline-content">
                <h3 data-aos="zoom-in" className="date">{t("History.Titletwo")}</h3>
                <img data-aos="zoom-in" src={gladys} alt="Sr gabriel Dianous"/>
            </div>
            </li>
            <li className="fourcard">
            <div className="timeline-content">
                <h3 className="date date-year">1991</h3>
                <h1>1991</h1>
                <p data-aos="zoom-in"> {t("History.Linethree")} </p>
            </div>
            </li>
            <li className="fourcard">
                <div className="timeline-content">
                <h3 data-aos="zoom-in" className="date">{t("History.Titlezero")}</h3>
                <img data-aos="zoom-in" src={teresita} alt="Sra Gladys y Gabriel"/>
                </div>
            </li>
            <li className="sixcard">
                <div className="timeline-content">
                <h3 data-aos="zoom-in" className="date date-year">1997</h3>
                <h1>1997</h1>
                <p data-aos="zoom-in" >{t("History.Linefive")}</p>
                </div>
            </li>
            <li className="sixcard">
                <div className="timeline-content">
                <h3 data-aos="zoom-in" className="date">{t("History.Titlefour")}</h3>
                <h1>Beneficio Ecologico</h1>
                <img data-aos="zoom-in" src={Eco} alt="asasd"></img>
                </div>
            </li>
          
            <li  className="sevencard">
                <div className="timeline-content">
                <h3 data-aos="zoom-in" className="date date-year">1998</h3>
                <h1>1998</h1>
                <p data-aos="zoom-in">{t("History.Linesix")} </p>
                </div>
            </li>
            <li className="sevencard">
                <div className="timeline-content">
                <h3 data-aos="zoom-in" className="date">{t("History.Titlefive")}</h3>
                <img data-aos="zoom-in" src={threegeneration} alt="Thirt generation"/>
                </div>
            </li>
        
          
            <li className="ninecard elevencard">
                <div className="timeline-content">
                <h3 data-aos="zoom-in" className="date">2000</h3>
                <p data-aos="zoom-in" >{t("History.Linenine")}</p>
                </div>
            </li>
            <li className="ninecard  elevencard" >
                <div className="timeline-content">
                <h3 data-aos="zoom-in" className="date">{t("History.Titlenine")}</h3>
                <img id="Exports--img" data-aos="zoom-in" src={exports}  alt="Happy fammily"/>
                </div>
            </li>

         
            <li className="ninecard twelvecard">
                <div className="timeline-content">
                <h3 data-aos="zoom-in" className="date">{t("History.Today")}</h3>
                <p data-aos="zoom-in" >{t("History.Lineten")}</p>
                </div>
            </li>
            <li className="ninecard  twelvecard" >
                <div className="timeline-content">
                <h3 data-aos="zoom-in" className="date">{t("History.Titleten")}</h3>
                <img data-aos="zoom-in" src={roasted} alt="Happy fammily"/>
                </div>
            </li>
            
            
        </ul>
        </div>
    </div>
        </div>

    );
}
