export const SliderDataThree = [
    {
        image:
          'https://objective-kare-2d6adc.netlify.app/assets/4.jpeg'
      },
    {
      image:
      'https://objective-kare-2d6adc.netlify.app/assets/10.jpeg'
    },
    {
        image:
        'https://objective-kare-2d6adc.netlify.app/assets/JapanPhotos/japan7.jpeg'
    }
    ,
    {
        image:
        'https://objective-kare-2d6adc.netlify.app/assets/12.jpeg'
    },
    {
        image:
        'https://objective-kare-2d6adc.netlify.app/assets/JapanPhotos/japan8.jpeg'
    }
    
    

  ];

