export const DataShopTwo = [
    {
        image:
          'https://objective-kare-2d6adc.netlify.app/assets/Shop/GYR09705.png'
      },
    {
      image:
      'https://objective-kare-2d6adc.netlify.app/assets/Shop/bannertwo.png'
    },
    {
        image:
        'https://objective-kare-2d6adc.netlify.app/assets/Shop/coffee1.jpeg'
      }

  ];
