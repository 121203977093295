import React,{useState} from 'react'
import {NavLink} from "react-router-dom"
import { useTranslation } from "react-i18next";
import togglemenu from "../assets/icons/Toggle.png";
import {  withRouter } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"
import  {closeSessionAction} from "../Redux/UserDucks"


const Navbar = (props) => {
    
    //Translations
    const [t, i18n] = useTranslation("global");

     //NavLinks
    const [showLinks, setShowLinks] = useState(false);
    
     //Sign Out
    const dispatch = useDispatch()

    const closession = () => {
        dispatch(closeSessionAction())
        props.history.push("/")
    }

    const active = useSelector(store => store.user.active)


    return(
        <nav className="nav">
        
        <div className="nav__menu" id={showLinks ? "hidden" : ""}>
          <ul className="nav__list">
            <li className="nav__item nav__item__shop">
              <NavLink to="/" className="nav__link activate ">
                {t("Header.Home")}
              </NavLink>
            </li>

            <li className="nav__item">
              <NavLink to="/History" className="nav__link">
                {t("Header.History")}
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink to="/Farms" className="nav__link">{t("Header.Farms")}
              </NavLink></li>

            <li className="nav__item">
              <NavLink to="/Coffeeshop" className="nav__link">
              {t("Header.Store")}
              </NavLink>
            </li>

            <li className="nav__item">
              <a href="#contact" className="nav__link">
                {t("Header.Contact")}
              </a>
            </li>
            <li className="nav__item"><NavLink to="/OurTeam" className="nav__link">{t("Header.Team")}</NavLink></li>

            <li className="nav__item nav__item__btns">
              <button
                className="LenguageButton"
                onClick={() => i18n.changeLanguage("en")}
              >
                EN
              </button>
              <button
                className="LenguageButton"
                onClick={() => i18n.changeLanguage("es")}
              >
                ES
              </button>
              {
                active ? (
                <>
                <button className="LenguageButton" onClick = {closession}>
                Cerrar Sesion
                </button>
                </>
              ):(  <NavLink to="/LogIn" className="nav__link__login"> <button className="Login--button" >{t("Header.Log-In")}
            </button></NavLink>)
            }
            </li>
              
            

          </ul>
        </div>
        <div>
          <img
            className="nav__toggle"
            id="nav-toggle"
            src={togglemenu}
            alt="toggle"
            onClick={() => setShowLinks(!showLinks)}
          ></img>
        </div>
      </nav>
  
    );


}

export default withRouter (Navbar)