import firebase from "firebase/app";
import "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyDDDP5T9-0EeSFefRps-v45qwcz_JK-FIc",
    authDomain: "cafetalera-grandelval.firebaseapp.com",
    projectId: "cafetalera-grandelval",
    storageBucket: "cafetalera-grandelval.appspot.com",
    messagingSenderId: "601541023006",
    appId: "1:601541023006:web:5410eb9480685d3b72a50f",
    measurementId: "G-2MPWW3ZQ66",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth()

export {auth,firebase}
