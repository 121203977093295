import React, {useEffect,Fragment} from "react";
import { useTranslation } from "react-i18next";
import "aos/dist/aos.css";
import instagram from "../assets/icons/instagram.svg"
import facebook from "../assets/icons/facebook.svg"
import Aos  from "aos"
import "aos/dist/aos.css"
import logored from "../assets/images/logored.png"
import whatsapp from "../assets/icons/whatsapp.svg"
import Delivery from "../assets/icons/delivery-truck.svg"
import Bag from "../assets/icons/coffee-bag.svg"
import World from "../assets/icons/international-delivery.svg"





export default function ContactForm(){
    const [t] = useTranslation("global");
    useEffect(() => {
        Aos.init({ duration:3000 });
    },[]);



        return(
            <Fragment>
            <div className="Container-contact-services">

                <section className="services--section" id="services"> 
        <h2 className="section--services--title">{t("Services.Services-title")}</h2>
        <div className="services__container">
            <div className="services__content">
                <img src={Delivery} alt="Icon" className="services__img"/>
                <h3 className="services__title">{t("Services.Delivery-title")}</h3>
                <p className="services__description">{t("Services.Delivery-text")}</p>
            </div>    
            <div className="services__content">
                <img src={Bag} alt="Icon" className="services__img"/>
                <h3 className="services__title">{t("Services.Coffee-title")}</h3>
                <p className="services__description">{t("Services.Coffee-text")}</p>
            </div>      
        
            <div className="services__content">
                <img src={World} alt="Icon" className="services__img"/>
                <h3 className="services__title">{t("Services.World-title")}</h3>
                <p className="services__description">{t("Services.World-text")}</p>
            </div>          
        </div>   

    </section>
                
            <div  className="container--form" id="contact">
            <img src="" className="square" alt="" />
            <div  data-aos="zoom-in" className="form-principal-container">
            <div className="contact-info">
                <h3 className="title">{t("Contact.Contact-title")}</h3>
            <p className="text-info-container">{t("Contact.Contact-info")}</p>
                <div className="info-contact-form">
                <div className="information">
                    <p>ventas@cafegrandelval.com</p>
                </div>
                <div className="information">
                </div>
                <div className="Contact-logo-container"><img src={logored} alt="Logo Red Grandelval"></img></div>
                </div>
        
                <div className="social-media">
                <p>{t("Contact.Connect")}</p>
                <div className="social-icons">
                    <a className="instagram" href="https://www.instagram.com/cafegrandelval/">
                    <img src={instagram} alt="logo ig"></img>
                    </a>
                    <a  className="facebook" href="https://www.facebook.com/Caf%C3%A9-Gran-del-Val-108574440968208/">
                    <img src={facebook} alt="logo ig"></img>
                    </a>
                </div>
                </div>
                <button className="whatsapp--button "><a href="https://wa.me/50762041934"><img src={whatsapp} alt="Our Whatsapp"></img></a></button>

            </div>
            <div className="contact-form">
        
            <form 
            name="contact-form"
            data-netlify="true"  
            className="principalform" 
            method="POST" 
            >
                <input type="hidden" name="form-name" value="contact-form"/>
                <h3 className="title">{t("Contact.Contact-form")}</h3>
                <div className="input-container">
                <label className="Label-form" htmlFor="">{t("Contact.Name")}</label>
                <input  type="text" name="name" className="input" />
                </div>
                <div  className="input-container">
                <label className="Label-form"  htmlFor="">{t("Contact.Email")}</label>
                <input  type="email" name="email" className="input" />
                </div>
                <div className="input-container">
                <label className="Label-form" htmlFor="">{t("Contact.Phone")}</label>

                <input type="tel" name="phone" className="input" />
                </div>
                <div className="input-container textarea">
                <label className="Label-form" htmlFor="">{t("Contact.Message")}</label>

                <textarea name="message" className="input"></textarea>
                </div>
                <input type="submit" value={t("Contact.Send")} className="btn" />
            </form>
            </div>
        </div>
        </div>
        </div>
        </Fragment>
        );
    };



