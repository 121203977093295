import React, { useState,useEffect, Fragment} from 'react';
import {DataShop} from "./DataShop/DataShop"
import {DataShopTwo} from "./DataShop/DataShopTwo"
import {DataShopThree} from "./DataShop/DataShopThree"
import { useTranslation } from "react-i18next";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';
import Aos  from "aos"
import "aos/dist/aos.css"

const ImgSliderShop = ({ slides }) => {
  const [t] = useTranslation("global");

    useEffect(() => {
        Aos.init({ duration:3000 });
        },[]);

    const [current, setCurrent] = useState(0);
    const length = slides.length;

    const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
    };

    if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
    }
    

  return (
    <Fragment>
      <div className="Title-slidershop-container"> <h1 className="Slider-title">{t("Product.Title")}</h1> </div> 
    <section className=" Shop-slider">
      {/* First Slider */}
    <section data-aos="zoom-in"  className='slider'>
      <AiOutlineArrowLeft className='left-arrow' onClick={prevSlide} />
      <AiOutlineArrowRight className='right-arrow' onClick={nextSlide} />
      {DataShop.map((slide, index) => {
        return (
          
          <div 
            className={index === current ? 'slide active' : 'slide'}
            key={index}
          >
            {index === current && (
              <img src={slide.image} alt='Gran del val images' className='image' />
            )}
            
          </div>
          
        );
      })}
    </section>

    {/* Second Slider */}
    <section data-aos="zoom-in"  className='slider'>
      <AiOutlineArrowLeft className='left-arrow' onClick={prevSlide} />
      <AiOutlineArrowRight className='right-arrow' onClick={nextSlide} />
      {DataShopTwo.map((slide, index) => {
        return (
          
          <div
            className={index === current ? 'slide active' : 'slide'}
            key={index}
          >
            {index === current && (
              <img src={slide.image} alt='Gran del val images' className='image' />
            )}
            
            
            
          </div>
          
        );
      })}
    </section>

        {/* Third Slider */}
    <section data-aos="zoom-in"  className='slider'>
      <AiOutlineArrowLeft className='left-arrow' onClick={prevSlide} />
      <AiOutlineArrowRight className='right-arrow' onClick={nextSlide} />
      {DataShopThree.map((slide, index) => {
        return (
          
          <div
            className={index === current ? 'slide active' : 'slide'}
            key={index}
          >
            {index === current && (
              <img src={slide.image} alt='Gran del val images' className='image' />
            )}
            
          </div>
          
        );
      })}
    </section>
    </section>
    </Fragment>
  );
};

export default ImgSliderShop;