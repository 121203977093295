export const DataShopThree = [
    {
        image:
          'https://objective-kare-2d6adc.netlify.app/assets/Shop/coffee6.jpeg'
      },
    {
      image:
      'https://objective-kare-2d6adc.netlify.app/assets/Shop/coffee8.jpeg'
    },
    {
        image:
        'https://objective-kare-2d6adc.netlify.app/assets/Shop/coffee3.jpeg'
      }

  ];
