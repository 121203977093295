

export const DataFortune = [
    {
        image:
          'https://objective-kare-2d6adc.netlify.app/assets/fortune1.jpg'
      },
      {
        image:
          'https://objective-kare-2d6adc.netlify.app/assets/fortune/geishathree.jpg'
      }
      ,
      {
        image:
          'https://objective-kare-2d6adc.netlify.app/assets/fortune/geishathree2.jpg'
      }
  ];

