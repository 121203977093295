import React,{Fragment, useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"
import Aos from "aos";
import goldenlogo from "../assets/images/goldenlogo.png";
import {loginuseraction} from "../Redux/UserDucks"
import {  withRouter } from "react-router-dom";


import "aos/dist/aos.css";
import Navbar from './NavBar';



export const FormLogIn = (props) => {

    //Translations 

    //Animations
    useEffect(() => {
    Aos.init({ duration: 3000 });
    }, []);



    //Firebase
    const dispatch = useDispatch()
    

    //When the user is login push him to the home page

    const loading = useSelector(store => store.user.loading)

    const active = useSelector(store => store.user.active)

    React.useEffect(()=> {
      if(active){
        props.history.push('/')
      }
      
    })

    return (
    <Fragment>
        <div className="Login-Container">
          <Navbar></Navbar>
        <div data-aos="zoom-in" className="Google-Card">
            <img className="Google-Card-Image"src={goldenlogo} alt="Golden Logo GrandelVal img"></img>
        <button 
        className="Google-btn" onClick={()=> dispatch(loginuseraction())}
        disabled={loading}
        >Sign up with google</button>
        </div>
        </div>
    </Fragment>

    )
}

export default withRouter (FormLogIn)

