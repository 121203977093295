export const DataGladys = [
    {
        image:
          'https://objective-kare-2d6adc.netlify.app/assets/gladysfarm/Gladys3.png'
      },
    {
      image:
      'https://objective-kare-2d6adc.netlify.app/assets/gladysfarm/gladys1.jpg'
    },
    {
      image:
      'https://objective-kare-2d6adc.netlify.app/assets/gladysfarm/gladys4.jpg'
    },
    {
      image:
      'https://objective-kare-2d6adc.netlify.app/assets/gladysfarm/gladys100.jpeg'
    },
    {
      image:
      'https://objective-kare-2d6adc.netlify.app/assets/gladysfarm/gladys200.jpeg'
    },
    {
      image:
      'https://objective-kare-2d6adc.netlify.app/assets/gladysfarm/gladys300.jpeg'
    },
    {
      image:
      'https://objective-kare-2d6adc.netlify.app/assets/gladysfarm/gladys400.jpeg'
      
    },  {
      image:
      'https://objective-kare-2d6adc.netlify.app/assets/gladysfarm/gladys500.jpeg'
      
    },





    

  ];

